import axios from 'axios'
const apiURL = process.env.REACT_APP_API_URL
const token = process.env.REACT_APP_API_TOKEN

const instance = axios.create({
  baseURL: apiURL,
  headers: {
    'content-type': 'application/json',
  },
})

async function client(
  endpoint,
  {data, headers: customHeaders, ...customConfig} = {},
) {
  const config = {
    url: `${endpoint}`,
    method: data ? 'POST' : 'GET',
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      'Content-Type': data ? 'application/json' : undefined,
      ...customHeaders,
    },
    ...customConfig,
  }

  return await instance(config)
}

export {client}
