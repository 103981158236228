import React from 'react'
import ReactDOM from 'react-dom'
import './bootstrap'
import {AppProviders} from 'context/index'
import {GlobalStyles} from 'styles/global-styles'
import App from './app'

ReactDOM.render(
  <React.StrictMode>
    <AppProviders>
      <GlobalStyles />
      <App />
    </AppProviders>
  </React.StrictMode>,
  document.getElementById('root'),
)
