import {Loading} from 'components/loading'
import React from 'react'
import {Switch, Route} from 'react-router-dom'
import {LoginModal} from 'components/login-modal'
import 'bootstrap/dist/css/bootstrap.min.css'
import {useAuthState} from 'react-firebase-hooks/auth'
import {auth} from './API/firebase'
import {userContext} from './userContext'
import 'bootstrap/dist/css/bootstrap.min.css'

const About = React.lazy(() => import('./screens/about'))
const Account = React.lazy(() => import('./screens/account'))
const Home = React.lazy(() =>
  import(/* webpackPrefetch: true */ './screens/home'),
)
const Game = React.lazy(() => import('./screens/game'))
const Users = React.lazy(() => import('./screens/users'))

function App() {
  const [showLogin, setShowLogin] = React.useState(false)
  const [isBetSlipVisible, setIsBetSlipVisible] = React.useState(false)
  const [isBetSlipBig, setIsBetSlipBig] = React.useState(true)
  const [isBetCard, setIsBetCard] = React.useState(false)
  const [user, loading, error] = useAuthState(auth)
  const openBetCard = ( ) => {
    setIsBetSlipVisible(true)
    setIsBetCard(true)
  }
  const functionsInObjectBetSlip = {
    openBetCard,
    isBetSlipBig,
    setIsBetSlipBig,
    isBetSlipVisible,
    setIsBetSlipVisible,
    isBetCard,
    setIsBetCard
  }

  return (
    <React.Suspense fallback={<Loading />}>
      <userContext.Provider 
      value={{user, functionsInObjectBetSlip}} 
      >
        <LoginModal show={showLogin} handleClose={() => setShowLogin(false)} />
        <AppRoutes setShowLogin={setShowLogin} user={user} />
      </userContext.Provider>
    </React.Suspense>
  )
}

function AppRoutes({setShowLogin, user}) {
  return (
    <Switch>
      <Route exact path="/">
        <Home setShowLogin={setShowLogin} />
      </Route>
      {user && (
        <Route exact path="/account">
          <Account />
        </Route>
      )}
      <Route exact path="/about">
        <About setShowLogin={setShowLogin} />
      </Route>
      <Route exact path="/league/:slug/:section">
        <Game setShowLogin={setShowLogin} />
      </Route>
      <Route exact path="/users">
        <Users />
      </Route>
    </Switch>
  )
}

export default App
