import {ThemeProvider} from '@emotion/react'
import * as React from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from 'react-query'
import {theme} from 'styles/theme'

const queryClient = new QueryClient()

function AppProviders({children}) {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <Router>{children}</Router>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export {AppProviders}
