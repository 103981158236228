const fallbackFonts =
  "system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';"

const theme = {
  colors: {
    backdrop: 'rgba(14, 51, 118, 0.7)',
    white: '#ffffff',
    lighterGray: '#e6e6e6',
    lightGray: '#979797',
    semiLightGray: '#B2BBC4',
    gray: '#717171',
    semiDarkGray: '#4D4D4D',
    darkGray: '#474747',
    backlightGray: '#F5F5F5',
    expGray: '#4D4D4D',
    lightRed: '#FF8282',
    red: '#c61022',
    darkRed: '#9a000f',
    brightRed: '#fe3232',
    cream: '#f3f7ff',
    lighterBlue: '#A2BEFF',
    lightblue: '#80a6ff',
    ultrLightBlue: '#CFDDFF',
    veryLightBlue: '#F3F7FF',
    blue: '#0956b2',
    brightBlue: '#4349FF',
    pabBlue: '#338BFF',
    washedBlue: '#80A6FF',
    darkBlue: '#0e3376',
    darkestBlue: '#00163D',
    altBlue: '#0E65CC',
    dropBlue: '#041762',
    altBarBlue: '#0241B1',
    barBlue: '#0C3C91',
    hoverBlue: '#053B9B',
    green: '#1caf00',
    darkOcean: '#041738',
    cyan: '#8FA1C1',
    marine: '#062458',
    lightMarine: '#4E81D9',
    lightBlack: '#000B1E',
    expertBlue: '#1567C9',
    lightGreen: '#B2FF92',
    gold: '#F6CD00',
  },
  fonts: {
    primary: `'Barlow', ${fallbackFonts}`,
    secondary: `'Manrope', ${fallbackFonts}`,
  },
  headerHeight: '84px',
  footerHeight: '184px',
  footerMobileHeight: '746px',
  footerTabletHeight: '418px',
}

export {theme}
