import qs from 'qs'
import * as React from 'react'
import {useQuery} from 'react-query'
import {client} from './api-client'

function useExperts(queryParams, options = {}) {
  let endpoint = 'AllExperts'
  if (queryParams) {
    endpoint += `?${qs.stringify(queryParams)}`
  }

  const query = useQuery({
    queryKey: ['Experts', endpoint],
    queryFn: () =>
      client(endpoint).then(response => {
        return response.data.data
      }),
    ...options,
    config: {
      ...options.config,
    },
  })

  const experts = React.useMemo(() => query.data ?? [], [query.data])
  const noExperts = React.useMemo(
    () => !Boolean(experts.length),
    [experts.length],
  )

  return {experts, noExperts, ...query}
}

function useExpertAvatar(expert, options = {}) {
  let endpoint = `ExpertAvatar?${qs.stringify({expert})}`
  const query = useQuery({
    queryKey: ['ExpertAvatar', endpoint],
    queryFn: () =>
      client(endpoint).then(response => {
        return response.data.data
      }),
    ...options,
    config: {
      ...options.config,
    },
  })

  return {expertAvatar: query.data ?? [], ...query}
}

function useBestExperts(queryParams, options = {}) {
  let endpoint = 'BestExperts'
  if (queryParams) {
    endpoint += `?${qs.stringify(queryParams)}`
  }

  const query = useQuery({
    queryKey: ['BestExperts', endpoint],
    queryFn: () =>
      client(endpoint).then(response => {
        return response.data.data
      }),
    ...options,
    config: {
      ...options.config,
    },
  })

  return {bestExperts: query.data ?? [], ...query}
}

function useExpertPicks(gameId, expertId, options = {}) {
  let endpoint = 'GameExpertPicks'
  endpoint += `?${qs.stringify({gameId, expertId})}`

  const query = useQuery({
    queryKey: ['GameExpertPicks', endpoint],
    queryFn: () =>
      client(endpoint).then(response => {
        return response.data.data
      }),
    ...options,
    config: {
      ...options.config,
    },
  })

  return {expertPicks: query.data ?? [], ...query}
}

export {useExperts, useBestExperts, useExpertAvatar, useExpertPicks}
