import {initializeApp} from 'firebase/app'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  deleteUser,
} from 'firebase/auth'
import {getDatabase, ref, set, update, get, child} from 'firebase/database'
import {
  ref as stoRef,
  getDownloadURL,
  uploadBytesResumable,
  getStorage,
} from 'firebase/storage'
import {useExperts} from 'utils/experts'

const firebaseConfig = {
  apiKey: 'AIzaSyBGyldOGpRMUUxI6PkyfKC74qAezsa_vo4',
  authDomain: 'pithia-87869.firebaseapp.com',
  databaseURL: 'https://pithia-87869-default-rtdb.firebaseio.com',
  projectId: 'pithia-87869',
  storageBucket: 'pithia-87869.appspot.com',
  messagingSenderId: '288353905016',
  appId: '1:288353905016:web:afdf1a6d63bc749cd4c08d',
  measurementId: 'G-54W6N5EDQK',
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getDatabase(app)

const googleProvider = new GoogleAuthProvider()

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    set(ref(db, 'users/' + user.uid), {
      name: user.displayName,
      authProvider: 'google',
      email: user.email,
      // profile_picture : imageUrl
    })
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}
const logInWithEmailAndPassword = async (email, password) => {
  let response = {error: false, message: ''}
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (err) {
    response = {error: true, message: parseFirebaseError(err)}
  }
  return response
}
const sendPasswordReset = async email => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}
const registerWithEmailAndPassword = async (name, email, password) => {
  let response = {error: false, message: ''}
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password)
    const user = res.user
    set(ref(db, 'users/' + user.uid), {
      name,
      email,
    })
  } catch (err) {
    response = {error: true, message: parseFirebaseError(err)}
  }
  return response
}

const storeAccountData = async ({name, email, location, timeZone}) => {
  await update(ref(db, 'users/' + auth.currentUser.uid), {
    name,
    email,
    location,
    timeZone,
  })
}

const storeProfilePicUrl = async url => {
  await update(ref(db, 'users/' + auth.currentUser.uid), {
    profilePic: url,
  })
}

const storeBetData = async betAmount => {
  await update(ref(db, 'users/' + auth.currentUser.uid), {
    betAmount,
  })
}

const getAccountData = async () => {
  const auth2 = getAuth(app)
  if (auth2.currentUser) {
    const userDataRef = ref(db, 'users/' + auth2.currentUser.uid)
    const userData = await get(userDataRef)
    return userData.val()
  } else {
    console.log('No user logged in')
  }
}

const changePassword = async (oldPassword, newPassword) => {
  try {
    const credential = await EmailAuthProvider.credential(
      auth.currentUser.email,
      oldPassword,
    )
    reauthenticateWithCredential(auth.currentUser, credential)
    await updatePassword(auth.currentUser, newPassword)
    alert('Your password has been changed!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const deleteAccount = async password => {
  console.log('Password', password)
  try {
    const credential = await EmailAuthProvider.credential(
      auth.currentUser.email,
      password,
    )
    await reauthenticateWithCredential(auth.currentUser, credential)
    await deleteUser(auth.currentUser)
    alert('Your account has been deleted!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const storeNotificationsData = async notifications => {
  console.log('notifications', notifications)
  await update(ref(db, 'users/' + auth.currentUser.uid), notifications)
}

const logout = () => {
  signOut(auth)
}

const storeBetsData = async bets => {
  const betObject = bets.reduce((a, v) => ({...a, [v.id]: v}), {})
  const userRef = ref(db, 'users/' + auth.currentUser.uid)
  get(child(userRef, 'bets')).then(bets => {
    if (bets.exists()) {
      const betsRef = ref(db, 'users/' + auth.currentUser.uid + '/bets')
      update(betsRef, betObject)
    } else {
      update(userRef, {
        bets: betObject,
      })
    }
  })
}

const followExpert = async expert => {
  console.log('Will follow expert', expert)
  if (auth.currentUser) {
    const userRef = ref(db, 'users/' + auth.currentUser.uid)
    get(child(userRef, 'experts')).then(experts => {
      if (experts.exists()) {
        const expertsRef = ref(db, 'users/' + auth.currentUser.uid + '/experts')
        update(expertsRef, {
          [expert]: 1,
        })
      } else {
        update(userRef, {
          experts: {[expert]: 1},
        })
      }
    })
  }
}

const unFollowExpert = async expert => {
  const userRef = ref(db, 'users/' + auth.currentUser.uid)
  get(child(userRef, 'experts')).then(experts => {
    if (experts.exists()) {
      const expertsRef = ref(db, 'users/' + auth.currentUser.uid + '/experts')
      update(expertsRef, {
        [expert]: 0,
      })
    }
  })
}

const getExpertIsFollowed = async expertId => {
  if (auth.currentUser) {
    let isFollowed = false
    if (expertId) {
      const userRef = ref(db, 'users/' + auth.currentUser.uid)
      const experts = await get(child(userRef, 'experts'))
      if (experts.exists()) {
        const expertsRef = ref(db, 'users/' + auth.currentUser.uid + '/experts')
        const expert = await get(child(expertsRef, expertId))
        if (expert.exists()) {
          if (expert.val() === 1) {
            isFollowed = true
          }
        }
      }
    }
    return isFollowed
  }
}

const getBetsData = async () => {
  const userRef = ref(db, 'users/' + auth.currentUser.uid)
  const bets = await get(child(userRef, 'bets'))
  return bets.val()
}

const getFollowedExperts = async () => {
  const userRef = ref(db, 'users/' + auth.currentUser.uid)
  const expertsData = await get(child(userRef, 'experts'))
  const rawExpertsData = expertsData.val()
  if (rawExpertsData) {
    const experts = Object.keys(rawExpertsData)
      .filter(key => rawExpertsData[key] === 1)
      .reduce((a, v) => ({...a, [v]: 1}), {})
    return experts
  } else {
    return null
  }
}

const uploadProfilePicture = async image => {
  if (!image) return
  const storage = getStorage()
  const storageRef = stoRef(storage, `files/${image.name}`)
  const uploadTask = uploadBytesResumable(storageRef, image)

  uploadTask.on(
    'state_changed',
    snapshot => {
      const progress = Math.round(
        (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      )
    },
    error => {
      alert(error)
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
        console.log('URL:', downloadURL)
        storeProfilePicUrl(downloadURL)
      })
    },
  )
}

const parseFirebaseError = error => {
  if (error.code === 'auth/email-already-in-use') {
    return 'Email already in use'
  } else if (error.code === 'auth/invalid-email') {
    return 'Invalid email'
  } else if (error.code === 'auth/weak-password') {
    return 'Weak password'
  } else if (error.code === 'auth/wrong-password') {
    return 'Wrong password'
  } else if (error.code === 'auth/user-not-found') {
    return 'User not found'
  } else if (error.code === 'auth/user-disabled') {
    return 'User disabled'
  } else if (error.code === 'auth/invalid-credential') {
    return 'Invalid credential'
  } else if (error.code === 'auth/invalid-verification-code') {
    return 'Invalid verification code'
  } else if (error.code === 'auth/invalid-verification-id') {
    return 'Invalid verification id'
  } else if (error.code === 'auth/operation-not-allowed') {
    return 'Operation not allowed'
  } else if (error.code === 'auth/popup-blocked') {
    return 'Popup blocked'
  } else if (error.code === 'auth/popup-closed-by-user') {
    return 'Popup closed by user'
  } else if (error.code === 'auth/unauthorized-domain') {
    return 'Unauthorized domain'
  } else if (error.code === 'auth/network-request-failed') {
    return 'Network request failed'
  } else if (error.code === 'auth/network-timeout') {
    return 'Network timeout'
  } else if (error.code === 'auth/too-many-requests') {
    return 'Too many requests'
  } else if (error.code === 'auth/user-token-expired') {
    return 'User token expired'
  } else {
    return 'Authentication error'
  }
}

export {
  auth,
  db,
  signInWithGoogle,
  sendPasswordReset,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  logout,
  storeAccountData,
  getAccountData,
  storeBetData,
  changePassword,
  deleteAccount,
  storeNotificationsData,
  storeBetsData,
  getBetsData,
  followExpert,
  getFollowedExperts,
  getExpertIsFollowed,
  unFollowExpert,
  uploadProfilePicture,
}
