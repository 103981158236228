import qs from 'qs'
import * as React from 'react'
import {useLocation} from 'react-router-dom'

const useMediaQuery = query => {
  const mediaQuery = React.useMemo(
    () => window.matchMedia(query.replace('@media ', '')),
    [query],
  )
  const [match, setMatch] = React.useState(() => mediaQuery.matches)

  React.useEffect(() => {
    const handler = () => {
      setMatch(mediaQuery.matches)
    }
    mediaQuery.addEventListener('change', handler)

    return () => mediaQuery.removeListener(handler)
  }, [mediaQuery])

  return match
}

function useQueryParams() {
  return qs.parse(useLocation().search.slice(1))
}

function useSidebarAnimation(
  isOpen,
  {animationDuration} = {animationDuration: 300},
) {
  const [mount, setMount] = React.useState(false)
  const [collapsed, setCollapsed] = React.useState(false)

  React.useEffect(() => {
    if (isOpen) {
      setMount(true)
      setTimeout(() => {
        setCollapsed(false)
      }, 1)
    } else {
      setCollapsed(true)
      setTimeout(() => {
        setMount(false)
      }, animationDuration)
    }
  }, [animationDuration, isOpen])

  return {mount, collapsed, animationDuration}
}

export {useMediaQuery, useQueryParams, useSidebarAnimation}
